import { useSiteMetadata } from './use-site-metadata';

type HeadData = {
	title?: string;
	tabName?: string;
	categorySlug?: string;
	guidelineCategory?: string;
	parentPageTitle?: string;
	subpageTabName?: string;
};

export const usePageTitle = ({
	title,
	tabName,
	categorySlug,
	guidelineCategory,
	parentPageTitle,
	subpageTabName,
}: HeadData) => {
	const { siteName } = useSiteMetadata();

	// guideline page for COMPONENT
	// e.g. Button - Examples - Components - Atlassian Design System
	if (guidelineCategory === 'Components' && !categorySlug && !subpageTabName) {
		return `${title} - ${tabName} - Components - ${siteName}`;
	} else if (!categorySlug && subpageTabName) {
		// guideline page for SUB-COMPONENT
		// e.g. Button Group - Examples - Components - Atlassian Design System
		return `${title} - ${subpageTabName} - Components - ${siteName}`;
	} else if (guidelineCategory && parentPageTitle) {
		// guideline page for |Brand|Foundations|Content|Resources| that IS a subpage
		// e.g. Messaging guidelines - Error messages - Content - Atlassian Design System
		return `${parentPageTitle} - ${title} - ${guidelineCategory} - ${siteName}`;
	} else if (categorySlug) {
		// overview page
		return `Overview - ${categorySlug} - ${siteName}`;
	} else if (guidelineCategory && !subpageTabName) {
		// guideline page for |Brand|Foundations|Content|Resources| that is NOT a subpage
		// e.g. Color palettes - Resources - Atlassian Design System
		return `${title} - ${guidelineCategory} - ${siteName}`;
	} else if (title) {
		// pages with no category
		// e.g. License - Atlassian Design System
		return `${title} - ${siteName}`;
	}

	// home page
	return `${siteName}`;
};
