import React from 'react';

import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import { usePageTitle } from '../utilities/use-page-title';
import { useSiteMetadata } from '../utilities/use-site-metadata';

export type MetaTagsProps = {
	title?: string;
	description?: string;
	tabName?: string;
	categorySlug?: string;
	guidelineCategory?: string;
	parentPageTitle?: string;
	subpageTabName?: string;
	/**
	 * Use for the base MetaTags implementation. All
	 * metatags will be added using fallback values where
	 * values are not provided. Without this option enabled,
	 * MetaTags will act as standard override behavior using
	 * 'react-helmet'.
	 */
	isGlobal?: boolean;
};

/**
 * __Meta tags__
 *
 * Meta tags for atlassian.design
 */
const MetaTags = ({
	title,
	parentPageTitle,
	description,
	tabName,
	categorySlug,
	guidelineCategory,
	subpageTabName,
	isGlobal,
}: MetaTagsProps) => {
	const { pathname } = useLocation();
	const { siteName, siteUrl, description: siteDefaultDescription, metaImage } = useSiteMetadata();
	const pageTitle = usePageTitle({
		title,
		tabName,
		categorySlug,
		guidelineCategory,
		parentPageTitle,
		subpageTabName,
	});

	const pageDescription = description ? `${description}` : `${siteDefaultDescription}`;
	const metaImageSrc = metaImage && metaImage.publicURL ? siteUrl + metaImage.publicURL : '';

	return (
		<>
			{isGlobal && (
				<Helmet>
					<html lang="en" />
					<meta property="og:type" content="website" />
					<meta property="twitter:card" content="summary_large_image" />
					<meta
						name="google-site-verification"
						content="FU9ZkoqotLLvSayuWsZITiRvaGcc53ZZM-GVy43LMY0"
					/>
					<meta property="og:site_name" content={siteName} />
					<meta property="og:url" content={`${siteUrl}${pathname.replace(/\/$/g, '')}`} />
					<meta property="og:image" content={metaImageSrc} />

					{process.env.GATSBY_CONSTELLATION_ENVIRONMENT !== 'PROD' && (
						<meta name="robots" content="noindex" />
					)}
				</Helmet>
			)}
			{(isGlobal || title) && (
				<Helmet>
					<title>{pageTitle}</title>
					<meta property="og:title" content={pageTitle} />
				</Helmet>
			)}
			{(isGlobal || description) && (
				<Helmet>
					<meta property="og:description" content={pageDescription} />
				</Helmet>
			)}
		</>
	);
};

export default MetaTags;
